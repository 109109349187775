<template>
  <v-container
          id="inventoryRender"
          fluid
          tag="section">
    <v-row
            align="center"
            justify="center">
      <v-col cols="12">
        <base-material-card
                color="green">
          <template v-slot:heading>
            <h1 class="display-2 font-weight-regular">
              <span  v-if="resultInventory && response.media">
                {{ response.media.kor_name }} > {{resultInventory}} >
              </span>
              인벤토리 수정
            </h1>
          </template>
          <v-card-text>
            <v-form
                  v-model="vModel.valid"
                  ref="form">
              <h2 style="padding: 20px 10px;" class="d-inline-block">
                인벤토리 삽입 코드
              </h2>
              <v-text-field
                      type="text"
                      color="secondary"
                      class="pl-3 pr-5"
                      disabled
                      :value="resultInventoryCode"/>
              <v-divider class="mt-5 mb-5" />
              <h2 style="padding: 20px 10px;" class="d-inline-block">
                Feed Title
              </h2>
              <v-text-field
                v-if="response.render"
                type="text"
                color="secondary"
                class="pl-3 pr-5"
                placeholder="피드 상단에 노출될 타이틀을 입력하세요"
                v-model="response.render.title"/>
              <v-divider class="mt-5 mb-5" />
              <h2 style="padding: 20px 10px;" class="d-inline-block">
                Feed Header Blocks
              </h2>
              <span>(리스트를 선택하세요.)</span>
              <ul class="blockquote">
                <li><strong>선택사항</strong>으로, 무한반복되는 Body Block 노출에 앞서 <strong>지정된 Block 설정 만큼만 노출</strong></li>
              </ul>
              <v-row
                      class="text-no-wrap"
                      align="start">
                <v-col cols="4" class="pt-0 pb-0">
                  <template v-if="resultHeader">
                    <v-data-table
                            style="border:1px solid #ddd; border-width: 1px 0;"
                            :headers="config.renderHeader"
                            :items="resultHeader"
                            :items-per-page="config.headerList.length"
                            item-key="id"
                            no-data-text="리스트가 없습니다."
                            hide-default-header
                            hide-default-footer>
                      <template v-slot:item="{ item, index }">
                        <tr>
                          <td class="text-center" @click="headerClick(item, index)">block{{index + 1}} {{ blockMetaHumanize(item) }}</td>
                          <td class="text-right" style="width:60px;">
                            <v-btn style="float: right" small @click="headerDelete(index)"><v-icon>mdi-delete</v-icon></v-btn>
                          </td>
                        </tr>
                      </template>
                    </v-data-table>
                  </template>
                  <v-btn @click="headerAdd" class="mt-5">block 추가</v-btn>
                </v-col>
                <template v-if="vModel.data.headerTitle">
                <v-col cols="4" class="pt-0 pb-0">
                  <h3>{{vModel.data.headerTitle}}</h3>
                  <v-row align="center">
                    <v-col cols="5" class="pt-0 pb-0">
                      <v-select
                              :items="config.skinOptions"
                              v-model="response.render.header[vModel.data.headerIdx].skin"
                              label="스킨 선택"
                              color="secondary"
                              :rules="config.skinRules"
                              required />
                    </v-col>
                    <v-col cols="3" class="pt-0 pb-0">
                      <v-select
                              :items="config.dimOptions"
                              v-model="response.render.header[vModel.data.headerIdx].dim[0]"
                              color="secondary" />
                      열
                    </v-col>
                    <v-col cols="1" class="pt-0 pb-0">
                      <h4>X</h4>
                    </v-col>
                    <v-col cols="3" class="pt-0 pb-0">
                      <v-select
                              :items="config.dimOptions"
                              v-model="response.render.header[vModel.data.headerIdx].dim[1]"
                              color="secondary" />
                      행
                    </v-col>
                  </v-row>
                  <h3 class="pt-4 pb-5">slot</h3>
                  <template v-if="resultHeaderSlot">
                  <v-data-table
                          style="border:1px solid #ddd; border-width: 1px 0;"
                          :headers="config.renderHeader"
                          :items="resultHeaderSlot"
                          :items-per-page="config.headerSlotList.length"
                          item-key="id"
                          no-data-text="리스트가 없습니다."
                          hide-default-header
                          hide-default-footer>
                    <template v-slot:item="{ item, index }">
                      <tr>
                        <td class="text-center" @click="headerSlotClick(item, index)">slot{{index + 1}} {{ slotMetaHumanize(item) }}</td>
                        <td class="text-right" style="width:60px;">
                          <v-btn style="float: right" small @click="headerSlotDelete(index)"><v-icon>mdi-delete</v-icon></v-btn>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                  </template>
                  <v-btn @click="headerSlotAdd" class="mt-5">slot 추가</v-btn>
                </v-col>
                <template v-if="vModel.data.headerSlotTitle">
                <v-col cols="4" class="pt-0 pb-0">
                  <h3 style="padding-top:150px;">{{vModel.data.headerSlotTitle}}</h3>
                  <template v-for="(item, index) in config.headerSlotList">
                    <v-row align="center" :value="item" :key="index" v-show="index === vModel.data.headerSlotIdx">
                      <v-col cols="5" class="pt-0 pb-0">
                        <v-select
                                :items="config.contentsOptions"
                                v-model="config.headerSlotList[index].skin"
                                label="컨텐츠 선택"
                                color="secondary"
                                :rules="config.contentsRules"
                                required
                                @change="headerSlotChange(index)"/>
                      </v-col>
                      <v-col cols="3" class="pt-0 pb-0">
                        <v-select
                                :items="config.dimOptions"
                                v-model="config.headerSlotList[index].dim[0]"
                                color="secondary"
                                @change="headerSlotChange(index)"/>
                        열
                      </v-col>
                      <v-col cols="1" class="pt-0 pb-0">
                        <h4>X</h4>
                      </v-col>
                      <v-col cols="3" class="pt-0 pb-0">
                        <v-select
                                :items="config.dimOptions"
                                v-model="config.headerSlotList[index].dim[1]"
                                color="secondary"
                                @change="headerSlotChange(index)"/>
                        행
                      </v-col>
                    </v-row>
                  </template>
                </v-col>
                </template>
                </template>
              </v-row>
            <v-divider class="mt-5 mb-5" />
              <h2 style="padding: 20px 10px;" class="d-inline-block">
                Feed Body Blocks
              </h2>
              <span>(리스트를 선택하세요.)</span>
              <ul class="blockquote">
                <li><strong>필수사항</strong>으로, <strong>Block 설계한 만큼 컨텐츠가 무한 반복됨</strong></li>
              </ul>
              <v-row
                      class="text-no-wrap"
                      align="start">
                <v-select v-if="response.render"
                  :items="config.bodyRepeatOptions"
                  v-model="response.render.body_repeat"
                  placeholder="FEED BODY BLOCK의 반복 노출 횟수를 선택하세요"
                  label="FEED BODY BLOCK의 반복 노출 설정"
                  color="secondary"
                  required/>
              </v-row>

              <v-row
                      class="text-no-wrap"
                      align="start">
                <v-col cols="4" class="pt-0 pb-0">
                  <template v-if="resultBody">
                    <v-data-table
                            style="border:1px solid #ddd; border-width: 1px 0;"
                            :headers="config.renderHeader"
                            :items="resultBody"
                            :items-per-page="config.bodyList.length"
                            item-key="id"
                            no-data-text="리스트가 없습니다."
                            hide-default-header
                            hide-default-footer>
                      <template v-slot:item="{ item, index }">
                        <tr>
                          <td class="text-center" @click="bodyClick(item, index)">block{{index + 1}} {{ blockMetaHumanize(item) }}</td>
                          <td class="text-right" style="width:60px;">
                            <v-btn style="float: right" small @click="bodyDelete(index)"><v-icon>mdi-delete</v-icon></v-btn>
                          </td>
                        </tr>
                      </template>
                    </v-data-table>
                  </template>
                  <v-btn @click="bodyAdd" class="mt-5">block 추가</v-btn>
                </v-col>
                <template v-if="vModel.data.bodyTitle">
                  <v-col cols="4" class="pt-0 pb-0">
                    <h3>{{vModel.data.bodyTitle}}</h3>
                    <v-row align="center">
                      <v-col cols="5" class="pt-0 pb-0">
                        <v-select
                                :items="config.skinOptions"
                                v-model="response.render.body[vModel.data.bodyIdx].skin"
                                label="스킨 선택"
                                color="secondary"
                                :rules="config.skinRules"
                                required />
                      </v-col>
                      <v-col cols="3" class="pt-0 pb-0">
                        <v-select
                                :items="config.dimOptions"
                                v-model="response.render.body[vModel.data.bodyIdx].dim[0]"
                                color="secondary" />
                        열
                      </v-col>
                      <v-col cols="1" class="pt-0 pb-0">
                        <h4>X</h4>
                      </v-col>
                      <v-col cols="3" class="pt-0 pb-0">
                        <v-select
                                :items="config.dimOptions"
                                v-model="response.render.body[vModel.data.bodyIdx].dim[1]"
                                color="secondary" />
                        행
                      </v-col>
                    </v-row>
                    <h3 class="pt-4 pb-5">slot</h3>
                    <template v-if="resultBodySlot">
                    <v-data-table
                            style="border:1px solid #ddd; border-width: 1px 0;"
                            :headers="config.renderHeader"
                            :items="resultBodySlot"
                            :items-per-page="config.bodySlotList.length"
                            item-key="id"
                            no-data-text="리스트가 없습니다."
                            hide-default-header
                            hide-default-footer>
                      <template v-slot:item="{ item, index }">
                        <tr>
                          <td class="text-center" @click="bodySlotClick(item, index)">slot{{index + 1}} {{ slotMetaHumanize(item) }}</td>
                          <td class="text-right" style="width:60px;">
                            <v-btn style="float: right" small @click="bodySlotDelete(index)"><v-icon>mdi-delete</v-icon></v-btn>
                          </td>
                        </tr>
                      </template>
                    </v-data-table>
                    </template>
                    <v-btn @click="bodySlotAdd" class="mt-5">slot 추가</v-btn>
                  </v-col>
                  <template v-if="vModel.data.bodySlotTitle">
                    <v-col cols="4" class="pt-0 pb-0">
                      <h3 style="padding-top:150px;">{{vModel.data.bodySlotTitle}}</h3>
                      <template v-for="(item, index) in config.bodySlotList">
                        <v-row align="center" :value="item" :key="index" v-show="index === vModel.data.bodySlotIdx">
                          <v-col cols="5" class="pt-0 pb-0">
                            <v-select
                                    :items="config.contentsOptions"
                                    v-model="config.bodySlotList[index].skin"
                                    label="컨텐츠 선택"
                                    color="secondary"
                                    :rules="config.contentsRules"
                                    required
                                    @change="bodySlotChange(index)"/>
                          </v-col>
                          <v-col cols="3" class="pt-0 pb-0">
                            <v-select
                                    :items="config.dimOptions"
                                    v-model="config.bodySlotList[index].dim[0]"
                                    color="secondary"
                                    @change="bodySlotChange(index)"/>
                            열
                          </v-col>
                          <v-col cols="1" class="pt-0 pb-0">
                            <h4>X</h4>
                          </v-col>
                          <v-col cols="3" class="pt-0 pb-0">
                            <v-select
                                    :items="config.dimOptions"
                                    v-model="config.bodySlotList[index].dim[1]"
                                    color="secondary"
                                    @change="bodySlotChange(index)"/>
                            행
                          </v-col>
                        </v-row>
                      </template>
                    </v-col>
                  </template>
                </template>
              </v-row>
              <v-divider class="mt-5 mb-5" />
            <h2 style="padding: 20px 10px;">
              컨텐츠 정렬 순서
            </h2>
            <v-row
                    class="text-no-wrap"
                    align="start">
              <v-col cols="2" class="pt-0 pb-0" v-if="response.render">
                <v-select
                        :items="config.orderOptions"
                        v-model="response.render.contents.sort"
                        placeholder="컨텐츠 정렬 순서를 선택하세요."
                        color="secondary"
                        :rules="config.orderRules"
                        required/>
              </v-col>
            </v-row>

            <v-divider class="mt-5 mb-5" />
            <h2 style="padding: 20px 10px;">
              매체사 컨텐츠의 노출 방식
            </h2>
            <v-row
              class="text-no-wrap"
              align="start">
              <v-col cols="2" class="pt-0 pb-0" v-if="response.render">
                <v-select
                  :items="config.contentDisplayTypeOptions"
                  v-model="response.render.contents.display_type"
                  placeholder="매체사 컨텐츠의 노출 방식을 선택하세요."
                  color="secondary"
                  required/>
              </v-col>
              <ul class="blockquote">
                <li><strong>기본적인 컨텐츠 노출 방식은</strong> 위 FEED Block에서 정의한 <strong>스킨에 따라 달라집니다.</strong></li>
                <li>다만, <strong>매체사 컨텐츠인 경우 스킨 설정에 따른 레이어/팝업 표시 방식(* 기본 설정)</strong>외에 페이지 이동, 바로 노출을 선택 할 수 있다.</li>
              </ul>
            </v-row>

            <v-divider class="mt-5 mb-5" />
              <h2 style="padding: 20px 10px;">
                이슈톡 컨텐츠의 노출 방식
              </h2>
              <v-row
                class="text-no-wrap"
                align="start">
                <v-col cols="2" class="pt-0 pb-0" v-if="response.render">
                  <v-select
                    :items="config.contentBfDisplayTypeOptions"
                    v-model="response.render.contents.bf_display_type"
                    placeholder="이슈톡 컨텐츠의 노출 방식을 선택하세요."
                    color="secondary"
                    required/>
                </v-col>
                <ol class="blockquote">
                  * 대상 CP
                  <li>내부 기사팀</li>
                  <li>RedFriday</li>
                </ol>
              </v-row>

              <v-divider class="mt-5 mb-5" />
              <h2 style="padding: 20px 10px;" class="d-inline-block">
                광고 아이디
              </h2>
              <span>(리스트를 선택하세요.)</span>
            <v-row
                    class="text-no-wrap"
                    align="start">
              <v-col cols="4" class="pt-0 pb-0">
                <v-data-table
                        style="border:1px solid #ddd; border-width: 1px 0;"
                        :headers="config.renderHeader"
                        :items="config.adsList"
                        :items-per-page="config.adsList.length"
                        item-key="id"
                        no-data-text="리스트가 없습니다."
                        @click:row="adsClick"
                        hide-default-header
                        hide-default-footer>
                  <template v-slot:item="{ item, index }">
                    <tr @click="adsClick(item, index)">
                      <td class="text-center">{{item.title.toUpperCase()}}
                        {{ adsMetaHumanize (item) }}
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-col>
              <template v-if="vModel.data.adsTitle">
                <v-col cols="4" class="pt-0 pb-0">
                  <h3>{{vModel.data.adsTitle.toUpperCase()}}용 광고 아이디</h3>
                  <p>
                    => 수익이 발생된 이력이 있는 광고 아이디는 삭제할 수 없습니다. 더 이상 필요하지않은 경우 음수(-) 값으로 변경해주세요.
                  </p>
                  <template v-if="this.vModel.data.adsIdx === 0">
                    <template v-for="(item, index) in vModel.adsArr.native">
                      <v-row align="center" class="pl-3" :key="index">
                      <v-text-field
                              label="ID"
                              type="number"
                              color="secondary"
                              v-model="vModel.adsArr.native[index]"
                              @change="adsChange"/>
                        <v-btn small @click="adsDelete(index)"><v-icon>mdi-delete</v-icon></v-btn>
                      </v-row>
                    </template>
                  </template>
                  <template v-else-if="this.vModel.data.adsIdx === 1">
                    <template v-for="(item, index) in vModel.adsArr.in_feed">
                      <v-row align="center" class="pl-3" :key="index">
                      <v-text-field
                              label="ID"
                              type="number"
                              color="secondary"
                              v-model="vModel.adsArr.in_feed[index]"
                              @change="adsChange"/>
                      <v-btn small @click="adsDelete(index)"><v-icon>mdi-delete</v-icon></v-btn>
                      </v-row>
                    </template>
                  </template>
                  <template v-else-if="this.vModel.data.adsIdx === 2">
                    <template v-for="(item, index) in vModel.adsArr.in_contents">
                      <v-row align="center" class="pl-3" :key="index">
                      <v-text-field
                              label="ID"
                              type="text"
                              color="secondary"
                              v-model="vModel.adsArr.in_contents[index]"
                              @change="adsChange"/>
                      <v-btn small @click="adsDelete(index)"><v-icon>mdi-delete</v-icon></v-btn>
                      </v-row>
                    </template>
                  </template>
                  <template v-else-if="this.vModel.data.adsIdx === 3">
                    <template v-for="(item, index) in vModel.adsArr.native_passback">
                      <v-row align="center" class="pl-3" :key="index">
                        <v-text-field
                            label="ID"
                            type="number"
                            color="secondary"
                            v-model="vModel.adsArr.native_passback[index]"
                            @change="adsChange"/>
                        <v-btn small @click="adsDelete(index)"><v-icon>mdi-delete</v-icon></v-btn>
                      </v-row>
                    </template>
                  </template>
                  <template v-if="this.vModel.data.adsIdx === 4">
                    <template v-for="(item, index) in vModel.adsArr.top_custom">
                      <v-row align="center" class="pl-3" :key="index">
                        <v-text-field
                            label="ID"
                            type="text"
                            color="secondary"
                            v-model="vModel.adsArr.top_custom[index]"
                            @change="adsChange"/>
                        <v-btn small @click="adsDelete(index)"><v-icon>mdi-delete</v-icon></v-btn>
                      </v-row>
                    </template>
                  </template>
                  <v-btn @click="adsIdAdd" class="mt-5">광고 아이디 추가</v-btn>
                </v-col>
              </template>
            </v-row>
            </v-form>
            <v-divider class="mt-5 mb-5" />
            <v-row align="center" class="mb-5" v-if="false">
              <v-btn color="default" @click="beforePage">이전</v-btn>
            </v-row>
            <h2 style="padding: 20px 10px;">
              상단 고정 컨텐츠
            </h2>
            <template v-if="resultContents">
              <v-row justify="space-around" align="center">
                <v-col cols="5" class="pb-0 pt-0">
                  <v-row class="mb-0" align="center">
                    <v-col cols="4" class="pt-0">
                      <v-select
                              :items="config.partnersOptions"
                              v-model="vModel.partner"
                              label="CP명"
                              color="secondary"
                              @change="submitKeyword"/>
                    </v-col>
                    <v-col cols="4" class="pt-0">
                      <form @submit.prevent="submitKeyword">
                        <v-text-field
                                v-model="keyword"
                                append-icon="mdi-magnify"
                                class="ml-auto pt-0"
                                label="Search"
                                color="secondary"
                                style="margin-top:-6px"
                                hide-details
                                single-line />
                      </form>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="1" class="pb-0">
                </v-col>
                <v-col cols="6" class="pb-0">
                  <template v-if="resultCollections && resultContents">
                    <v-row justify="space-between" align="center">
                      <v-col>
                        <v-btn @click="upStep" small><v-icon>mdi-arrow-up-bold</v-icon></v-btn>
                        <v-btn @click="downStep" small><v-icon>mdi-arrow-down-bold</v-icon></v-btn>
                      </v-col>
                    </v-row>
                  </template>
                </v-col>
              </v-row>
              <v-row justify="space-around">
                <v-col cols="5">
                  <div style="max-height:540px;overflow-y: auto;border: 1px solid rgba(0, 0, 0, 0.12);"
                       v-on:scroll="infiniteHandler">
                    <div ref="contentCols" v-if="resultContents.length > 0">
                      <v-data-table
                              v-model="vModel.listSelected1"
                              :headers="config.headers1"
                              :items="resultContents"
                              :items-per-page="resultContents.length"
                              item-key="num"
                              no-data-text="리스트가 없습니다."
                              @click:row="tableRowClick"
                              show-select
                              hide-default-footer>
                        <template v-slot:item.title="{ item }">
                          <p class="text-left ma-0">{{ item.title }}</p>
                        </template>
                        <template v-slot:item.category="{ item }">
                          <p style="width:70px;" class="ma-0">{{ item.type }}</p>
                        </template>
                        <template v-slot:item.created="{ item }">
                          <p style="width:80px;" class="ma-0">{{ item.created.slice(0, 10) }}</p>
                        </template>
                        <template v-slot:item.partner.name="{ item }">
                          <p style="width:65px;" class="ma-0">{{ item.partner.name }}</p>
                        </template>
                      </v-data-table>
                    </div>
                  </div>
                </v-col>
                <v-col cols="1" class="text-center">
                  <v-btn
                          style="position: relative; top: 50%; margin-top: -20px !important;"
                          class="ma-1"
                          color="warning"
                          @click="collectionClick"
                          :disabled="this.vModel.listSelected1.length === 0"
                          fab
                          small>
                    <v-icon center>
                      mdi-arrow-right-bold
                    </v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="6">
                  <template v-if="resultCollections && resultContents">
                    <v-row style="max-height:540px;overflow-y: auto;border: 1px solid rgba(0, 0, 0, 0.12);">
                      <v-col cols="12">
                        <v-data-table
                                v-model="vModel.listSelected2"
                                :headers="config.headers2"
                                :items="resultCollections"
                                :items-per-page="Number(resultCollections.length)"
                                item-key="num"
                                no-data-text="리스트가 없습니다."
                                @click:row="tableRowClick2"
                                show-select
                                hide-default-footer>
                          <template v-slot:item.title="{ item }">
                            <p class="text-left ma-0">{{ item.title }}</p>
                          </template>
                          <template v-slot:item.category="{ item }">
                            <p style="width:70px;" class="ma-0">{{ item.type }}</p>
                          </template>
                          <template v-slot:item.created="{ item }">
                            <p style="width:80px;" class="ma-0">{{ item.created.slice(0, 10) }}</p>
                          </template>
                          <template v-slot:item.partner.name="{ item }">
                            <p style="width:65px;" class="ma-0">{{ item.partner.name }}</p>
                          </template>
                          <template v-slot:item.delete="{ item }">
                            <v-btn small @click="listClose(item.contents_id)"><v-icon>mdi-delete</v-icon></v-btn>
                          </template>
                        </v-data-table>
                      </v-col>
                    </v-row>
                  </template>
                  <template v-else>
                    <p>지정된 컨텐츠가 없습니다.</p>
                  </template>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-btn
                        class="mt-5"
                        color="default"
                        @click="beforePage">
                  취소
                </v-btn>
                <v-btn
                        :disabled="!vModel.valid"
                        class="mt-5"
                        color="success"
                        @click="submitSelected('save')">
                  저장
                </v-btn>
              </v-row>
            </template>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
    <base-material-snackbar
            v-model="vModel.snackbar"
            :type="vModel.snackbarType"
            v-bind="{
        'top': true,
        'right': true
      }">
      <span style="display:block;width:300px;margin:0;">{{vModel.errorMessage}}</span>
    </base-material-snackbar>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import { common } from '../../mixins/common.js'

export default {
  mixins: [ common ],
  created () {
    this.refresh()
  },
  data () {
    return {
      page: 1,
      response: {
        contents: null,
        media: null,
        mediaList: null,
        render: null,
        config: null,
        inventory: null,
        renderData: null,
        contentsList: null,
        collections: null,
        collectionsAll: null,
        lastPage: 2
      },
      keyword: null,
      vModel: {
        data: {
          headerIdx: null,
          headerTitle: null,
          headerSlotIdx: null,
          headerSlotTitle: null,
          bodyIdx: null,
          bodyTitle: null,
          bodySlotIdx: null,
          bodySlotTitle: null,
          adsIdx: null,
          adsTitle: null,
          collection_order: null,
          header: {},
          headerSlot: {},
          body: {},
          bodySlot: {},
          ads: [],
        },
        adsArr: {
          native: [],
          native_passback: [],
          in_feed: [],
          in_contents: [],
          top_custom: []
        },
        listSelected1: [],
        listSelected2: [],
        resultListSelected: null,
        resultListChk: [],
        collections: [],
        media: '',
        partner: '',
        page: 1,
        per: 20,
        snackbar: false,
        snackbarType: 'warning',
        errorMessage: null,
      },
      config: {
        linkPartners: [],
        partnersOptions: [],
        skinOptions: [],
        contentsOptions: [],
        orderOptions: [],
        contentDisplayTypeOptions: [],
        dimOptions: [1, 2, 3, 4, 6, 12],
        headerList: [],
        headerSlotList: [],
        bodyList: [],
        bodySlotList: [],
        adsList: [
          { id: 1, title: 'native' },
          { id: 2, title: 'in_feed' },
          { id: 3, title: 'in_contents' },
          { id: 4, title: 'native_passback' },
          { id: 5, title: 'top_custom' }
        ],
        renderHeader: [ { text: 'title', value: 'title', align: 'center' }, { text: 'btn', value: 'btn', align: 'center' } ],
        perOptions: [ 20, 50, 100 ],
        headers1: [
          { text: '제목', value: 'title', align: 'center' },
          { text: '종류', value: 'category', align: 'center' },
          { text: '등록일', value: 'created', align: 'center' },
          { text: 'CP명', value: 'partner.name', align: 'center' },
          //  text: '본문', value: 'page', align: 'center' },
        ],
        headers2: [
          // { text: '', value: 'drag', align: 'center' },
          // { text: 'No', value: 'no', align: 'center' },
          { text: '제목', value: 'title', align: 'center' },
          { text: '종류', value: 'category', align: 'center' },
          { text: '등록일', value: 'created', align: 'center' },
          { text: 'CP명', value: 'partner.name', align: 'center' },
          { text: '제외', value: 'delete', align: 'center' },
        ],
        bodyRepeatOptions: [
          { text: '무한 반복 노출', value: 0 },
          { text: '1회만 노출 (반복 안함)', value: 1 },
          { text: '2회 반복 노출', value: 2 },
          { text: '4회 반복 노출', value: 4 },
          { text: '6회 반복 노출', value: 6 },
          { text: '8회 반복 노출', value: 8 },
          { text: '12회 반복 노출', value: 12 },
        ],
        orderRules: [ v => !!v || '정렬 순서를 입력하세요.' ],
        skinRules: [ v => !!v || '스킨을 선택하세요.' ],
        contentsRules: [ v => !!v || '컨텐츠를 선하세요.' ],
      }
    }
  },
  methods: {
    ...mapActions(['xhttp']),
    snack (type, val) {
      this.vModel.errorMessage = val
      this.vModel.snackbarType = type
      this.vModel.snackbar = true
    },
    headerAdd () {
      this.config.headerList.push({ 'skin': '', 'dim': [2, 2], 'block': [] })
    },
    headerClick (item, index) {
      this.vModel.data.header = item
      this.vModel.data.headerTitle = 'block' + (index + 1)
      this.vModel.data.headerIdx = index
      this.vModel.data.headerSlot = null
      this.vModel.data.headerSlotIdx = null
      this.vModel.data.headerSlotTitle = ''
      let slotArr = []
      for (let i = 0; i < this.vModel.data.header.block.length; i++) {
        let val = this.vModel.data.header.block[i].split('@')
        let dim = val[1].split('x')
        slotArr.push({ skin: val[0], dim: [Number(dim[0]), Number(dim[1])] })
      }
      this.config.headerSlotList = slotArr
    },
    headerDelete (idx) {
      this.response.render.header.splice(idx, 1)
      this.vModel.data.headerTitle = ''
    },
    headerSlotAdd () {
      if (this.config.headerSlotList.length === 0) {
        this.config.headerSlotList = []
      }
      this.config.headerSlotList.push({ 'skin': '', 'dim': [1, 1] })
    },
    headerSlotClick (item, index) {
      if (this.vModel.data.headerSlotIdx) {
        this.$set(this.config.headerSlotList, this.vModel.data.headerSlotIdx, this.vModel.data.headerSlot)
      }
      this.vModel.data.headerSlot = item
      this.vModel.data.headerSlotTitle = 'slot' + (index + 1)
      this.vModel.data.headerSlotIdx = index
    },
    headerSlotChange (idx) {
      let slot = []
      for (let j = 0; j < this.config.headerSlotList.length; j++) {
        if (this.config.headerSlotList[j].skin) {
          slot.push(this.config.headerSlotList[j].skin + '@' + this.config.headerSlotList[j].dim[0] + 'x' + this.config.headerSlotList[j].dim[1])
        }
      }
      this.response.render.header[this.vModel.data.headerIdx].block = slot
    },
    headerSlotDelete (idx) {
      this.config.headerSlotList.splice(idx, 1)
      this.headerSlotChange()
      this.vModel.data.headerSlotTitle = ''
    },
    bodyAdd () {
      this.config.bodyList.push({ 'skin': '', 'dim': [2, 2], 'block': [] })
    },
    bodyClick (item, index) {
      this.vModel.data.body = item
      this.vModel.data.bodyTitle = 'block' + (index + 1)
      this.vModel.data.bodyIdx = index
      this.vModel.data.bodySlot = null
      this.vModel.data.bodySlotIdx = null
      this.vModel.data.bodySlotTitle = ''
      let slotArr = []
      for (let i = 0; i < this.vModel.data.body.block.length; i++) {
        let val = this.vModel.data.body.block[i].split('@')
        let dim = val[1].split('x')
        slotArr.push({ skin: val[0], dim: [Number(dim[0]), Number(dim[1])] })
      }
      this.config.bodySlotList = slotArr
    },
    bodyDelete (idx) {
      this.response.render.body.splice(idx, 1)
      this.vModel.data.bodyTitle = ''
    },
    bodySlotAdd () {
      if (this.config.bodySlotList.length === 0) {
        this.config.bodySlotList = []
      }
      this.config.bodySlotList.push({ 'skin': '', 'dim': [1, 1] })
    },
    bodySlotClick (item, index) {
      if (this.vModel.data.bodySlotIdx) {
        this.$set(this.config.bodySlotList, this.vModel.data.bodySlotIdx, this.vModel.data.bodySlot)
      }
      this.vModel.data.bodySlot = item
      this.vModel.data.bodySlotTitle = 'slot' + (index + 1)
      this.vModel.data.bodySlotIdx = index
    },
    bodySlotChange (idx) {
      let slot = []
      for (let j = 0; j < this.config.bodySlotList.length; j++) {
        if (this.config.bodySlotList[j].skin) {
          slot.push(this.config.bodySlotList[j].skin + '@' + this.config.bodySlotList[j].dim[0] + 'x' + this.config.bodySlotList[j].dim[1])
        }
      }
      this.response.render.body[this.vModel.data.bodyIdx].block = slot
    },
    bodySlotDelete (idx) {
      this.config.bodySlotList.splice(idx, 1)
      this.bodySlotChange()
      this.vModel.data.bodySlotTitle = ''
    },
    adsIdAdd () {
      let idx = this.vModel.data.adsIdx
      if (idx === 0) {
        this.vModel.adsArr.native.push('')
      } else if (idx === 1) {
        this.vModel.adsArr.in_feed.push('')
      } else if (idx === 2) {
        this.vModel.adsArr.in_contents.push('')
      } else if (idx === 3) {
        this.vModel.adsArr.native_passback.push('')
      } else if (idx === 4) {
        this.vModel.adsArr.top_custom.push('')
      }
    },
    adsClick (item, index) {
      this.vModel.data.adsTitle = item.title
      this.vModel.data.adsIdx = index
    },
    adsChange () {
      this.response.render.native.target_id = this.vModel.adsArr.native
      this.response.render.native_passback.target_id = this.vModel.adsArr.native_passback
      this.response.render.in_feed.target_id = this.vModel.adsArr.in_feed
      this.response.render.in_contents.target_id = this.vModel.adsArr.in_contents
      this.response.render.top_custom.target_id = this.vModel.adsArr.top_custom
    },
    adsDelete (index) {
      let idx = this.vModel.data.adsIdx
      if (idx === 0) {
        this.vModel.adsArr.native.splice(index, 1)
      } else if (idx === 1) {
        this.vModel.adsArr.in_feed.splice(index, 1)
      } else if (idx === 2) {
        this.vModel.adsArr.in_contents.splice(index, 1)
      } else if (idx === 3) {
        this.vModel.adsArr.native_passback.splice(index, 1)
      } else if (idx === 4) {
        this.vModel.adsArr.top_custom.splice(index, 1)
      }
    },
    infiniteHandler (value) {
      let height = this.$refs.contentCols.clientHeight - 550
      let currentScrollPosition = value.srcElement.scrollTop
      if (height < currentScrollPosition && this.page < this.response.lastPage) {
        this.page = ++this.vModel.page
        this.contentsList()
      }
    },
    tableRowClick (val) {
      let arr = this.vModel.listSelected1.filter(function ($value) {
        return $value.key === val.key
      })
      if (arr.length > 0) {
        let result = this.vModel.listSelected1.filter(function ($value) {
          return $value.key !== val.key
        })
        this.vModel.listSelected1 = result
      } else {
        this.vModel.listSelected1.push(val)
      }
    },
    tableRowClick2 (val) {
      if (this.vModel.listSelected2.length > 0) {
        if (this.vModel.listSelected2[0].key === val.key) {
          this.$set(this.vModel.listSelected2, 0, {})
        } else {
          this.$set(this.vModel.listSelected2, 0, val)
        }
      } else {
        this.$set(this.vModel.listSelected2, 0, val)
      }
    },
    upStep () {
      // 전체 값
      let arr = this.vModel.collections
      // 체크된 값
      let data = this.vModel.listSelected2
      this.vModel.listSelected2 = []
      // 체크된 값들의 번째 빼내기
      let indexArr = data.map(function ($value) {
        return $value.num
      })
      if (indexArr[0] > 0) {
        // 제일 처음 값이 0번째가 아닐 때
        // 해당 번째 값을 null로
        for (let i = 0; i < indexArr.length + 1; i++) {
          arr[indexArr[i]] = null
        }
        // 체크되지 않은 값들만 담기
        arr = arr.filter(n => n)
        // 체크한 값들이 빠져나간 곳의 뒤의 값들을 따로 담기
        let a = arr.splice(indexArr[0], arr.length - 1)
        // 체크한 값들 중 제일 먼저 있었던 자리보다 1개 앞에 값들 넣기
        let num = -1
        for (let i = 0; i < indexArr.length; i++) {
          arr.splice(indexArr[0] + num, 0, data[i])
          this.vModel.listSelected2.push(data[i])
          this.vModel.listSelected2[i].num = indexArr[0] + num
          num++
        }
        // 뒤의 값들을 배열 뒤에 붙이기
        arr = arr.concat(a)
        // 다시 순서값 부여
        for (let i = 0; i < arr.length; i++) {
          arr[i].num = i
        }
        this.vModel.collections = arr
      } else {
        this.vModel.listSelected2 = data
      }
    },
    downStep () {
      // 전체 값
      let arr = this.vModel.collections
      // 체크된 값
      let data = this.vModel.listSelected2
      this.vModel.listSelected2 = []
      // 체크된 값들의 번째 빼내기
      let indexArr = data.map(function ($value) {
        return $value.num
      })
      if (indexArr[indexArr.length - 1] < arr.length - 1) {
        // 제일 처음 값이 0번째가 아닐 때
        // 해당 번째 값을 null로
        for (let i = 0; i < indexArr.length + 1; i++) {
          arr[indexArr[i]] = null
        }
        // 체크한 값 뒤의 값들을 따로 담기
        let a = arr.splice(indexArr[indexArr.length - 1] + 2, this.vModel.collections.length - 1)
        // 체크되지 않은 값들만 담기
        arr = arr.filter(n => n)
        // 체크되지 않은 값들 수
        let length = arr.length
        // 체크되지 않은 값들 뒤에 체크했던 값 붙이기
        arr = arr.concat(data)
        // 체크값 v-model 재설정
        for (let i = 0; i < indexArr.length; i++) {
          this.vModel.listSelected2.push(data[i])
          this.vModel.listSelected2[i].num = length
          length++
        }
        // 배열에 체크 뒤에 있던 값 붙이기
        arr = arr.concat(a)
        // 다시 순서값 부여
        for (let i = 0; i < arr.length; i++) {
          arr[i].num = i
        }
        this.vModel.collections = arr
      } else {
        this.vModel.listSelected2 = data
      }
    },
    submitSelected (val) {
      if (confirm('저장하겠습니까?')) {
        let contents = this.vModel.collections.map(function ($value) {
          return $value.contents_id
        })
        this.response.render.contents.manually = contents
        this.response.render.ad = {
          native: this.response.render.native.target_id,
          native_passback: this.response.render.native_passback.target_id,
          in_feed: this.response.render.in_feed.target_id,
          in_contents: this.response.render.in_contents.target_id,
          top_custom: this.response.render.top_custom.target_id,
        }
        let send = this.response.render
        this.xhttp({
          url: '/inventory/' + this.$route.params.inventory_id + '/render',
          method: 'put',
          data: send,
          self: this
        }).then((response) => {
          if (response.status === 200) {
            this.snack('success', '저장 완료되었습니다.')
            this.beforePage()
          }
        }).catch((error) => {
          // xhttp.js 에서 이미 catch 처리했는지 검증
          if ('catchPreProcessed' in error && error.catchPreProcessed) {
            return
          }

          if (error.message === 'Request failed with status code 401') {
            this.$router.push({ 'name': 'UserLogin' })
          } else {
            this.snack('warning', '관리자에게 문의 바랍니다.')
          }
        })
      }
    },
    collectionClick () {
      let chk = this.vModel.listSelected1
      let contents
      for (let j = 0; j < chk.length; j++) {
        contents = this.response.contents.collection.filter(function ($value) {
          return $value.key !== chk[j].key
        })
      }
      this.$set(this.response.contents, 'collection', contents)
      this.$set(this.response, 'contentsList', contents)
      let num = this.vModel.collections.length
      for (let i = 0; i < chk.length; i++) {
        chk[i].num = num + i
      }
      this.vModel.collections = this.vModel.collections.concat(chk)
      this.vModel.listSelected1 = []
      this.vModel.listSelected2 = []
    },
    submitKeyword () {
      let send = {}
      if (this.keyword) {
        send.keyword = this.keyword
      }
      if (this.vModel.partner) {
        send.partner = this.vModel.partner
      }
      if (this.$route.query.page) {
        this.vModel.page = Number(this.$route.query.page)
        send.page = this.vModel.page
      }
      if (this.$route.query.per) {
        this.vModel.per = this.$route.query.per
        send.per = this.vModel.per
      }
      if (['InventoryRender'].includes(this.$route.name)) {
        this.$router.replace({ name: 'InventoryRender', query: send })
      } else {
        this.$router.replace({ name: 'mediaRender', query: send })
      }
      this.contentsList('refresh')
    },
    numReplace (val) {
      let num = val + ''
      const result = num.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      return result
    },
    listClose (val) {
      if (confirm('제외하겠습니까?')) {
        this.vModel.collections = this.vModel.collections.filter(function ($value) {
          return $value.contents_id !== val
        })
      }
    },
    refresh () {
      if (this.$route.query.page) {
        this.vModel.page = Number(this.$route.query.page)
      }
      if (this.$route.query.per) {
        this.vModel.per = Number(this.$route.query.per)
      }
      if (this.$route.query.keyword) {
        this.keyword = this.$route.query.keyword
      }
      if (this.$route.query.partner) {
        this.vModel.partner = this.$route.query.partner
      }
      if (this.$route.name === 'InventoryRender') {
        this.mediaList()
      } else {
        this.getMedia()
        this.getInventory()
      }
      this.getConfig()
      this.getRender()
    },
    getConfig () {
      this.xhttp({
        url: '/inventory/config',
        method: 'get',
        params: null
      }).then((response) => {
        if (response.status === 200) {
          this.response.config = response.data.data
          this.config.skinOptions = this.response.config.skin
          this.config.contentsOptions = this.response.config.contents
          this.config.orderOptions = this.response.config.sort.splice(0, 4)
          this.config.contentDisplayTypeOptions = this.response.config.content_display_types
          this.config.contentBfDisplayTypeOptions = this.response.config.content_bf_display_types
        }
      }).catch((error) => {
        if (error.message === 'Request failed with status code 401') {
          this.$router.push({ 'name': 'UserLogin' })
        } else {
          this.snack('warning', '관리자에게 문의 바랍니다.')
        }
      })
    },
    getRender () {
      this.xhttp({
        url: '/inventory/' + this.$route.params.inventory_id + '/render',
        method: 'get',
        params: null
      }).then((response) => {
        if (response.status === 200) {
          this.response.render = response.data.data
          this.config.headerList = this.response.render.header
          this.config.bodyList = this.response.render.body
          if (this.response.render.native.target_id) {
            this.vModel.adsArr.native = this.response.render.native.target_id
          }
          if (this.response.render.native_passback.target_id) {
            this.vModel.adsArr.native_passback = this.response.render.native_passback.target_id
          }
          if (this.response.render.in_feed.target_id) {
            this.vModel.adsArr.in_feed = this.response.render.in_feed.target_id
          }
          if (this.response.render.in_contents.target_id) {
            this.vModel.adsArr.in_contents = this.response.render.in_contents.target_id
          }
          if (this.response.render.top_custom.target_id) {
            this.vModel.adsArr.top_custom = this.response.render.top_custom.target_id
          }
          this.response.collections = this.response.render.contents.manually
          if (this.response.collections) {
            for (let i = 0; i < this.response.collections.length; i++) {
              this.response.collections[i].num = i
            }
            this.vModel.collections = this.response.collections
          }
        }
      }).catch((error) => {
        if (error.message === 'Request failed with status code 401') {
          this.$router.push({ 'name': 'UserLogin' })
        } else {
          this.snack('warning', '관리자에게 문의 바랍니다.')
        }
      })
    },
    getInventory () {
      let media
      if (['InventoryRender'].includes(this.$route.name)) {
        media = this.response.mediaList[0].key
      } else {
        media = this.$route.params.media_id
      }

      let send = {
        resolution: 'lo',
      }

      this.xhttp({
        url: '/' + media + '/inventory/' + this.$route.params.inventory_id,
        method: 'get',
        params: send
      }).then((response) => {
        if (response.status === 200) {
          this.response.inventory = response.data.data
          // this.allCollectionsList()
        }
      }).catch((error) => {
        if (error.message === 'Request failed with status code 401') {
          this.$router.push({ 'name': 'UserLogin' })
        } else {
          this.snack('warning', '관리자에게 문의 바랍니다.')
        }
      })
    },
    mediaList () {
      let send = {
        orderby: 'created',
        direction: 'desc',
        resolution: 'lo',
      }
      this.xhttp({
        url: '/media',
        method: 'get',
        params: send
      }).then((response) => {
        if (response.status === 200) {
          this.response.mediaList = response.data.data.media
          this.getMedia()
          this.getInventory()
        }
      }).catch((error) => {
        if (error.message === 'Request failed with status code 401') {
          this.$router.push({ 'name': 'UserLogin' })
        } else {
          this.snack('warning', '관리자에게 문의 바랍니다.')
        }
      })
    },
    contentsList (val) {
      this.response.contents = null
      let send = {
        page: this.vModel.page,
        per: this.vModel.per,
        is_on: true
      }
      if (this.$route.query.keyword) {
        send.keyword = this.keyword
      }
      if (this.$route.query.partner) {
        send.partner = this.vModel.partner
      }
      let media
      if (['InventoryRender'].includes(this.$route.name)) {
        media = this.response.mediaList[0].key
      } else {
        media = this.$route.params.media_id
      }
      this.xhttp({
        url: '/' + media + '/collections/' + this.$route.params.inventory_id,
        method: 'get',
        params: send
      }).then((response) => {
        if (response.status === 200) {
          this.response.contents = response.data.data
          this.response.lastPage = this.response.contents.meta.lastPage
          for (let i = 0; i < this.response.contents.collection.length; i++) {
            this.response.contents.collection[i].num = i + (this.page * 100)
          }
          if (val === 'refresh') {
            this.response.contentsList = this.response.contents.collection
          } else {
            this.response.contentsList = this.response.contentsList.concat(this.response.contents.collection)
          }
        }
      }).catch((error) => {
        if (error.message === 'Request failed with status code 401') {
          this.$router.push({ 'name': 'UserLogin' })
        } else {
          this.snack('warning', '관리자에게 문의 바랍니다.')
        }
      })
    },
    getMedia () {
      let media
      if (['InventoryRender'].includes(this.$route.name)) {
        media = this.response.mediaList[0].key
      } else {
        media = this.$route.params.media_id
      }

      let send = {
        resolution: 'lo',
      }

      this.xhttp({
        url: '/media/' + media,
        method: 'get',
        params: send
      }).then((response) => {
        if (response.status === 200) {
          this.response.media = response.data.data.media
          this.config.linkPartners = null
          if (this.response.media.linkPartner.length > 0) {
            this.config.linkPartners = this.response.media.linkPartner.map(function (val) {
              if (val.partner_approved === 1) {
                return { value: val.partner_id, text: val.partner.name }
              }
            })
            this.getPartners()
          }
          this.contentsList('refresh')
        }
      }).catch((error) => {
        if (error.message === 'Request failed with status code 401') {
          this.$router.push({ 'name': 'UserLogin' })
        } else {
          this.snack('warning', '관리자에게 문의 바랍니다.')
        }
      })
    },
    getPartners () {
      let send = {
        per: 999,
        page: 1,
        orderby: 'created',
        direction: 'desc'
      }
      this.xhttp({
        url: '/partners',
        method: 'get',
        params: send
      }).then((response) => {
        if (response.status === 200) {
          this.response.partner = response.data.data.partners
          for (let i = 0; i < this.response.partner.length; i++) {
            for (let j = 0; j < this.config.linkPartners.length; j++) {
              if (this.response.partner[i].id === this.config.linkPartners[j].value) {
                this.config.partnersOptions.push({ value: this.response.partner[i].key, text: this.response.partner[i].name })
              }
            }
          }
          this.config.partnersOptions.unshift({ value: '', text: '전체' })
        }
      }).catch((error) => {
        if (error.message === 'Request failed with status code 401') {
          this.$router.push({ 'name': 'UserLogin' })
        } else {
          this.snack('warning', '관리자에게 문의 바랍니다.')
        }
      })
    },
    allCollectionsList () {
      let send = {
        page: 1,
        per: this.response.inventory.limit,
        is_on: true
      }
      let media
      if (['InventoryRender'].includes(this.$route.name)) {
        media = this.response.mediaList[0].key
      } else {
        media = this.$route.params.media_id
      }
      this.xhttp({
        url: '/' + media + '/collections/' + this.$route.params.inventory_id,
        method: 'get',
        params: send
      }).then((response) => {
        if (response.status === 200) {
          this.response.collectionsAll = response.data.data.collection
        }
      }).catch((error) => {
        if (error.message === 'Request failed with status code 401') {
          this.$router.push({ 'name': 'UserLogin' })
        } else {
          this.snack('warning', '관리자에게 문의 바랍니다.')
        }
      })
    },
    /**
     * Humanize된 Slot 정보 반환
     * @param blockItem
     * @returns {string}
     */
    blockMetaHumanize (blockItem) {
      let skinName = blockItem.skin
      for (let item of this.config.skinOptions) {
        if (item.value === blockItem.skin) {
          skinName = item.text
          break
        }
      }

      if (skinName === '') {
        return ''
      }
      return `/ ${skinName}(${blockItem.dim[0]}x${blockItem.dim[1]})`
    },
    /**
     * Humanize된 Slot 정보 반환
     * @param slotItem
     * @returns {string}
     */
    slotMetaHumanize (slotItem) {
      let contentType = slotItem.skin
      for (let item of this.config.contentsOptions) {
        if (item.value === slotItem.skin) {
          contentType = item.text
          break
        }
      }

      if (contentType === '') {
        return ''
      }
      return `/ ${contentType}(${slotItem.dim[0]}x${slotItem.dim[1]})`
    },
    /**
     * Humanize된 광고 크드 정보 반환
     * @param item
     */
    adsMetaHumanize (item) {
      let key = item.title
      let ads = this.vModel.adsArr[key]
      let tmp = []
      if (ads.length > 0) {
        for (let value of ads) {
          if (value) {
            tmp.push(parseInt(value))
          }
        }
        if (tmp.length > 0) {
          return tmp
        } else {
          return ''
        }
      }
      return ''
    }
  },
  computed: {
    resultInventoryCode () {
      if (this.response.inventory) {
        return '<ins class="adsbyaiinfeed" data-aiinfeed-inv="' + this.response.inventory.id + '"></ins>'
      } else {
        return false
      }
    },
    resultInventory () {
      if (this.response.inventory) {
        return this.response.inventory.title
      } else {
        return false
      }
    },
    resultHeader () {
      if (this.config.headerList.length > 0) {
        return this.config.headerList
      } else {
        return false
      }
    },
    resultHeaderSlot () {
      if (this.config.headerSlotList.length > 0) {
        return this.config.headerSlotList
      } else {
        return false
      }
    },
    resultBody () {
      if (this.config.bodyList.length > 0) {
        return this.config.bodyList
      } else {
        return false
      }
    },
    resultBodySlot () {
      if (this.config.bodySlotList.length > 0) {
        return this.config.bodySlotList
      } else {
        return false
      }
    },
    resultContents () {
      if (this.response.contentsList) {
        return this.response.contentsList
      } else {
        return false
      }
    },
    resultCollections () {
      if (this.vModel.collections.length > 0) {
        return this.vModel.collections
      } else {
        return false
      }
    },
    resultCollections2 () {
      if (this.vModel.resultListSelected) {
        return this.vModel.resultListSelected
      } else {
        return false
      }
    }
  },
  watch: {
    keyword: function (val) {
      if (val === '') {
        this.submitKeyword()
      }
    }
  }
}
</script>

<style>
  #inventoryRender table .tableTitle {
    cursor: pointer;
  }
  #inventoryRender table .tableTitle:hover {
    text-decoration: underline;
  }
</style>
